import { render, staticRenderFns } from "./CreateBrokerDialog.vue?vue&type=template&id=95b2abea&"
import script from "./CreateBrokerDialog.vue?vue&type=script&lang=js&"
export * from "./CreateBrokerDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports