<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-sheet
        v-bind="attrs"
        v-on="on"
        :color="selected ? `${sheetColor} darken-4` : `${sheetColor} lighten-2`"
        rounded
        elevation="4"
        :height="small ? 40 : 70"
        @click="sheetClick"
        :outlined="selected ? true : false"
        :style="
          (disable ? 'cursor: pointer' : 'cursor:pointer') + ' ;' + sheetStyle
        "
        :class="`${selected ? 'white--text' : 'black--text'}`"
      >
        <div
          class="mt-auto align-center justify-center d-flex px-2 fill-height"
        >
          <v-icon
            :color="selected ? `${sheetColor} lighten-4` : `${sheetColor}`"
            large
            >{{ iconKey }}</v-icon
          >
          <span
            :class="
              'condition-type-font ml-3 text-truncate' +
              (selected ? ' white--text' : '')
            "
            :style="`font-size: ${fontSize}`"
            >{{ item[itemLabel].toUpperCase() }}
          </span>
        </div>
      </v-sheet>
    </template>
    <span>{{ item[itemLabel].toUpperCase() }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "SelectionSheet",

  props: {
    type: {
      default: "",
    },

    item: {
      required: true,
    },

    color: {
      default: "grey",
    },

    itemKey: {
      required: true,
    },

    itemLabel: {
      required: true,
    },

    iconKey: {
      default: "mdi-broadcast",
    },

    disable: {
      default: false,
    },

    sheetStyle: {
      default: "",
    },

    fontSize: {
      type: String,
      default: "1rem",
    },

    small: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: "type",
    event: "update-item",
  },

  computed: {
    localType: {
      get: function () {
        return this.type;
      },

      set: function (v) {
        this.$emit("update-item", v);
      },
    },

    selected() {
      return this.type == this.item[this.itemKey];
    },

    sheetColor() {
      return this.disable ? "grey" : this.color;
    },
  },

  methods: {
    sheetClick() {
      if (!this.disable) this.localType = this.item[this.itemKey];
    },
  },
};
</script>

<style>
.condition-type-font {
  font-weight: 500;
  letter-spacing: 0.1666666667em !important;
  line-height: 2rem;
  text-transform: uppercase;
  font-family: Roboto, sans-serif !important;
}
</style>